<template>
  <div :class="mdAndUp ? 'd-flex flex-row' : ''">
    <!--Left Sheet-->
    <v-sheet
      color="transparent"
      :height="left_sheet_height"
      :width="left_sheet_width"
    >
      <!--Buttons Section-->
      <v-sheet
        height="100%"
        width="100%"
        :color="mdAndUp ? 'white' : 'transparent'"
        :class="mdAndUp ? 'd-flex align-center' : 'd-flex align-center mt-10'"
        :elevation="mdAndUp ? 2 : 0"
      >
        <ProfilesEvaluationButtonsSection />
      </v-sheet>
    </v-sheet>
    <!--Right Sheet-->

    <v-sheet
      class=""
      color="transparent"
      :height="left_sheet_height"
      :width="right_sheet_width"
    >
      <v-sheet :height="right_sheet_height" width="100%" color="transparent">
        <!--Table-->
        <v-row dense>
          <v-col cols="12">
            <div v-if="lgAndUp">
              <CandidatesDesktopTable
                :table_top_sheet_height="table_top_sheet_height"
                :table_sheet_height="table_sheet_height"
                :candidates_count="candidates_count"
              />
            </div>
            <div v-else>
              <CandidatesMobileTable
                :itemsPerPage="itemsPerPage"
                :candidates_count="candidates_count"
              />
            </div>
          </v-col>
        </v-row>
      </v-sheet>

      <!--Buttons Section for Mobile Responsive-->
      <v-sheet
        class="hidden-md-and-up"
        color="transparent"
        :height="left_sheet_height"
        :width="left_sheet_width"
      >
        <v-sheet
          height="100%"
          width="100%"
          color="transparent"
          class="d-flex align-center hidden-md-and-up"
          elevation="0"
        >
          <ProfilesEvaluationButtonsSectionMobile />
        </v-sheet>
      </v-sheet>

      <!--Footer-->
      <v-sheet
        :height="footer_height"
        width="100%"
        color="transparent"
        class="d-flex align-center"
      >
        <v-row wrap no-gutters :class="mdAndUp ? '' : 'mx-3 mb-3'">
          <v-col cols="12" md="12">
            <div class="text-justify footer bg-transparent w-100 h-100">
              <p :class="mdAndUp ? 'mx-6' : ''">
                {{ footer }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapState, mapMutations } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

//API Services
import ProfileEvaluationService from "@/services/ProfileEvaluationService";
import TagsService from "@/services/TagsService";

// Components
import ProfilesEvaluationButtonsSection from "@/components/DashboardComponents/ProfilesEvaluation/ProfilesEvaluationButtonsSection.vue";
import ProfilesEvaluationButtonsSectionMobile from "@/components/DashboardComponents/ProfilesEvaluation/ProfilesEvaluationButtonsSectionMobile.vue";

import CandidatesDesktopTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidatesDesktopTable.vue";
import CandidatesMobileTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidatesMobileTable.vue";
// // import MyPagination from "@/components/DashboardComponents/ProfilesEvaluation/MyPagination.vue";
//import CandidatesTableMobile from "@/components/DashboardComponents/ProfilesEvaluation/CandidatesTableMobile.vue";
export default defineComponent({
  name: "CandidatesGeneralView",
  data() {
    return {
      //Table Title
      table_title: "Evaluación de Perfiles",

      height: window.innerHeight,

      //Label
      label: "Etiquetas",

      //Text Field Search Placeholder
      search_placeholder: "Escribe y filtra por la palabra que quieras",
      filter: "",

      //Components Props
      candidates_count: 0,

      footer: "",
    };
  },
  components: {
    ProfilesEvaluationButtonsSection,
    //LabelsMenu,
    CandidatesDesktopTable,
    CandidatesMobileTable,
    ProfilesEvaluationButtonsSectionMobile,
    // // MyPagination,
    //CandidatesTableMobile,
  },

  computed: {
    ...mapState([
      "user_info",
      "detailed_view_step",
      "search_table",
      "candidates",
      "search",
      "defaultParametrization",
    ]),
  },

  methods: {
    ...mapMutations([
      "setCandidates",
      "setTags_list",
      "setExternal_link",
      "setEmptyCandidates",
      "setTotalParametization",
      "setDefaultParametrization",
      "setTeamAndCompanyAdjustment",
    ]),

    async loadCandidatesGeneralView() {
      try {
        const response =
          await ProfileEvaluationService.loadCandidatesGeneralView(
            this.user_info.tokens.tokenPE,
            "es"
          );
        let data = response.data;

        if (data.filas == 0) {
          this.candidates_count = 0;
          this.setExternal_link(data.linkCandidato);
          this.setEmptyCandidates();
        } else {
          this.candidates_count = data.filas;
          this.setExternal_link(data.linkCandidato);
          this.setCandidates(data);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async loadAllTags() {
      try {
        const response = await TagsService.getAllTags(
          this.user_info.tokens.tokenPE
        );
        let data = response.data;

        this.setTags_list(data);
      } catch (error) {
        console.log(error);
      }
    },
    async getDefaultParametrization() {
      try {
        const response = await ProfileEvaluationService.getDefaultParametrization(
          this.user_info.tokens.tokenPE
        );
        
        this.setDefaultParametrization(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getListofIDealParams() {
      try {
        const response = await ProfileEvaluationService.getListofIDealParams(
          this.user_info.tokens.tokenPE
        );
        let data = response.data;
        this.setTotalParametization(data);
      } catch (error) {
        console.log(error);
      }
    },
    async reqProjectListDataRaw(projectToken) {
      try {
        const response =
          await ProfileEvaluationService.getProjectListDataRaw(
            projectToken,
          );

        if (response.status !== 200) console.log(`No hay proyecto con token ${projectToken}`)

        this.setTeamAndCompanyAdjustment(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.footer = texts[62];
    },
  },

  async created() {
    await this.getDefaultParametrization();
    await this.loadCandidatesGeneralView();
    await this.getListofIDealParams();
    await this.loadAllTags();
    await this.reqProjectListDataRaw(this.user_info.tokens.tokenDC);

    this.set_texts();
  },
  watch: {
    detailed_view_step(newVal) {
      if (newVal === 0) {
        this.getDefaultParametrization();
        this.getListofIDealParams();
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { lgAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const left_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * window.innerHeight) / 100;
        case "lg":
          return (92 * window.innerHeight) / 100;
        case "xl":
          return (92 * window.innerHeight) / 100;
        case "xxl":
          return (92 * window.innerHeight) / 100;
      }

      return undefined;
    });
    const left_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (18 * window.innerWidth) / 100;
        case "lg":
          return (18 * window.innerWidth) / 100;
        case "xl":
          return (18 * window.innerWidth) / 100;
        case "xxl":
          return (18 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const right_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (82 * window.innerWidth) / 100;
        case "lg":
          return (82 * window.innerWidth) / 100;
        case "xl":
          return (82 * window.innerWidth) / 100;
        case "xxl":
          return (82 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const right_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const table_top_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (10 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (15 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (13 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (12 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });
    const table_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (
            (90 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "lg":
          return (
            (77 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "xl":
          return (
            (83 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "xxl":
          return (
            (84 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
      }

      return undefined;
    });

    const footer_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const itemsPerPage = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "md":
          return 8;
        case "lg":
          return 10;
        case "xl":
          return 15;
        case "xxl":
          return 20;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      left_sheet_height,
      left_sheet_width,
      right_sheet_height,
      table_top_sheet_height,
      table_sheet_height,
      right_sheet_width,
      footer_height,
      itemsPerPage,
      mdAndUp,
      lgAndUp,
      xsAndUp,
    };
  },
});
</script>

<style scoped>
.search_tf {
  font-style: italic;
}

.container {
  background-color: #fbfbfb;
}

.footer {
  font-size: 60%;
  font-style: italic;
  color: #585858;
}

.filter_icon {
  background-color: #00c3c0;
}

.testing {
  height: 100% !important;
}
</style>
