<template>
  <v-sheet color="transparent" :height="bottom_sheet_height" width="100%">
    <v-row dense style="height: 100%">
      <!--Company Engagement-->
      <v-col cols="12" md="3" class="text-center cardwhiteIV2">
        <v-row dense>
          <v-col cols="12">
            <h3 class="text-uppercase" style="color: #34495e">
              {{ title1_line2 }}
              {{ mindsetIdealFitData.paramName || 'N/D' }}
            </h3>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="d-flex justify-center">
            <div>
              <p class="subtitle">
                {{ subtitle1 }} {{ mindsetIdealFitData.paramName || 'N/D' }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" :class="mdAndUp ? '' : 'd-flex justify-center'">
            <PersonVsIdeaEngagementChart />
          </v-col>
        </v-row>
      </v-col>

      <!--Kpis Engagement Graphic-->
      <v-col
        cols="12"
        md="3"
        :class="
          mdAndUp ? 'text-center  cardwhiteIV3' : 'text-center cardwhiteIV3'
        "
      >
        <!--Title-->

        <h3 class="text-uppercase" style="color: #34495e">
          {{ title2 }} {{ mindsetIdealFitData.paramName || 'N/D' }}
        </h3>
        <p class="subtitle">
          {{ subtitle2 }} {{ mindsetIdealFitData.paramName || 'N/D' }}
        </p>

        <!--Progress Circular-->
        <v-row dense class="d-flex justify-center">
          <v-col cols="6">
            <v-row dense class="mt-5">
              <v-col cols="12">
                <v-progress-circular
                  class="mb-1"
                  :rotate="360"
                  :size="120"
                  :width="8"
                  :model-value="mindsetIdealFitData.mindsetFitPercent"
                  :color="color1"
                  bg-color="transparent"
                >
                  <v-avatar size="105" :color="background_color1">
                    <h1 style="color: #34495e">
                      {{ mindsetIdealFitData.mindsetFitPercent }}
                    </h1>
                  </v-avatar>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!--Legend-->
        <v-row dense :class="mdAndUp ? 'mt-10' : 'mt-10 mx-5'">
          <v-col cols="12">
            <div class="rounded-pill">
              <v-row dense class="d-flex justify-center">
                <V-col cols="4">
                  <h5>BAJO</h5>
                </V-col>
                <V-col cols="4">
                  <h5>ESTÁNDAR</h5>
                </V-col>
                <V-col cols="4">
                  <h5>ALTO</h5>
                </V-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row dense :class="mdAndUp ? '' : 'mx-3'">
          <v-col cols="12">
            <div class="rounded-pill legend py-3 w-100">
              <v-row dense class="d-flex justify-center mx-1">
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #fffc8b"
                  ></div>
                  <p class="ml-1 legend_text">Menos de 70</p>
                </V-col>
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #cadfb8"
                  ></div>
                  <p class="ml-1 legend_text">70-80</p>
                </V-col>
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #b1d095"
                  ></div>
                  <p class="ml-1 legend_text">Más de 80</p>
                </V-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <!--Company Engagement-->

      <v-col cols="12" md="5" class="text-center cardwhiteIV2">
        <v-row dense>
          <v-col cols="12">
            <h3 class="text-uppercase" style="color: #34495e">
              {{ title3_line1 }}{{ mindsetIdealFitData.paramName || 'N/D' }}
            </h3>
            <p class="subtitle">
              {{ subtitle3 }} {{ mindsetIdealFitData.paramName || 'N/D' }}
            </p>
          </v-col>

          <v-col cols="12" :class="mdAndUp ? '' : 'd-flex justify-center'">
            <Spider36ValuesChart />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useDisplay } from "vuetify";

// Components

import PersonVsIdeaEngagementChart from "@/components/DashboardComponents/ProfilesEvaluation/PersonVsIdeaEngagementChart.vue";
/* import Desired_OrgCulturalEngagementChart from "@/components/DashboardComponents/ProfilesEvaluation/Desired_OrgCulturalEngagementChart.vue"; */
import Spider36ValuesChart from "@/components/DashboardComponents/ProfilesEvaluation/Spider36ValuesChart.vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "CandidateEngagementView",

  components: {
    PersonVsIdeaEngagementChart,
    /*    Desired_OrgCulturalEngagementChart, */
    Spider36ValuesChart,
  },

  data: () => ({
    //Labels
    title1_line1: "Ajuste Cultural",
    title1_line2: " Gráfico de Ajuste Mentalidad persona y ",
    subtitle1:
      "Identifica gráficamente en qué grado encaja la mentalidad de la persona con la de ",

    title2: "kpi de ajuste mentalidad persona y",

    title3_line1: "Gráfico de ajuste valores de la persona y ",
    title3_line2: "Mentalidad Persona-Cultura Deseada",
    subtitle2: "Identifica numéricamente en que grado encaja la mentalidad de la persona con la de",
    subtitle3:
      "Identifica gráficamente en qué grado encajan los valores de la persona con los de",
  }),

  computed: {
    ...mapState(["mindsetIdealFitData"]),
    color1() {
      return this.color_mindsetFitPercent();
    },

    /*     color2() {
      return this.color_des_org_fit();
    }, */

    background_color1() {
      return this.background_color_mindsetFitPercent();
    },

    /*   background_color2() {
      return this.background_color_des_org_fit();
    }, */
  },

  methods: {
    color_mindsetFitPercent() {
      if (this.mindsetIdealFitData.mindsetFitPercent < 70) {
        return "#F7E66B";
      }
      if (this.mindsetIdealFitData.mindsetFitPercent > 80) {
        return "#8ABE66";
      }
      if (
        this.mindsetIdealFitData.mindsetFitPercent >= 70 &&
        this.mindsetIdealFitData.mindsetFitPercent <= 80
      ) {
        return "#CADFB8";
      }
    },

    background_color_mindsetFitPercent() {
      if (this.mindsetIdealFitData.mindsetFitPercent < 70) {
        return "#FBF3BB";
      }
      if (this.mindsetIdealFitData.mindsetFitPercent > 80) {
        return "#C9E1B9";
      }
      if (
        this.mindsetIdealFitData.mindsetFitPercent >= 70 &&
        this.mindsetIdealFitData.mindsetFitPercent <= 80
      ) {
        return "#E7F0DE";
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    const { name } = useDisplay();

    const bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    return { mdAndUp, bottom_sheet_height };
  },
});
</script>

<style scoped>
.subtitle {
  color: #585858;
  font-weight: bold;
  font-size: 14px;
}

.col2 {
  border-left: 2px solid #00c3c0 !important;
  border-bottom: 2px solid #00c3c0 !important;
}
.col1 {
  border-right: 1px solid #00c3c0 !important;
  border-bottom: 2px solid #00c3c0 !important;
}

.legend {
  border: 1px solid black !important;
}

.legend_text {
  font-size: 13px;
}
.cardwhiteIV2 {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #efefef;

  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 10%;
}
.cardwhiteIV3 {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #efefef;

  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 10%;
}
</style>
