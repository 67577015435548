import API from "./API";

export default {
  loadCandidatesGeneralView(tokenProyecto, idioma) {
    let endpoint = "dashboard/" + tokenProyecto + "/" + idioma;
    return API().get(endpoint);
  },

  //CultureProfile
  loadCandidateCultureProfile(projectToken, candidateToken, leng) {
    let endpoint =
      "candidato_perfil/" + projectToken + "/" + candidateToken + "/" + leng;
    return API().get(endpoint);
  },

  //KPIS Engagement //añadir aquí mindset fit
  loadCandidateEngagement(projectToken, candidateToken, leng) {
    let endpoint =
      "candidato_kpi/" + projectToken + "/" + candidateToken + "/" + leng;
    return API().get(endpoint);
  },

  deleteCandidate(candidateToken) {
    let endpoint = "dashboard/archivar/" + candidateToken;
    return API().post(endpoint);
  },
  //Report & //Comportamiento Ético
  getCandidateReportData(projectToken, candidateToken, language) {
    let endpoint =
      "report/" + projectToken + "/" + candidateToken + "/" + language;
    return API().get(endpoint);
  },
  //Liderazgo
  getCandidateLeadershipData(candidateToken, language) {
    let endpoint = "reporte/lider/" + candidateToken + "/" + language;
    return API().get(endpoint);
  },

  //Parametrizar
  getParametrizationData(candidateToken, language) {
    let endpoint = "params/show/" + candidateToken + "/" + language;
    return API().get(endpoint);
  },

  postParametrizeCandidate(name, data, proyecto_token) {
    let endpoint = "params";
    return API().post(endpoint, {
      name: name,
      data: data,
      proyecto_token: proyecto_token,
    });
  },
  UpdateParametrizeCandidate(name, data, id) {
    let endpoint = "params";
    return API().put(endpoint, {
      data: data,
      name: name,
      id: id,
    });
  },
  DeleteParametrizeCandidate(id) {
    let endpoint = "params";
    return API().delete(endpoint, {
      data: { id: id },
    });
  },

  getListofIDealParams(proyecto_token) {
    let endpoint = "params/list/" + proyecto_token;
    return API().get(endpoint);
  },
  postParamSelectedForCandidate(parameter_id, tokenCandidato) {
    let endpoint = "params/assignParam";
    return API().post(endpoint, {
      parameter_id,
      tokenCandidato,
    });
  },

  postParamSelectedForCandidateBulk(parameter_id, tokenCandidatesList) {
    let endpoint = "params/assignParamBulk";
    return API().post(endpoint, {
      parameter_id,
      tokenCandidatesList,
    });
  },

  /**
   * Obtiene el mindsetFIt de un candidato con respecto a un proyecto en particular
   * @param {string} candidateToken 
   * @param {string} projectToken 
   */
  getCandidateDataForMindsetFit(candidateToken, projectToken) {
    let endpoint = `project-parameters/${candidateToken}/${projectToken}`;
    return API().get(endpoint);
  },

  /**
   * Setea o actualiza la parametrización por defecto de un proyecto específico
   * @param {number | null} paramId 
   * @param {string} projectToken 
   */
  setDefaultParametrization(paramId, projectToken) {
    let endpoint = "params/setDefaultParam";
    return API().post(endpoint, {
      token_cli: projectToken,
      default_param_id: paramId,
    });
  },

  /**
   * Obtiene la parametrización por defecto de un proyecto utilizando el token del proyecto
   * @param {string} projectToken 
   */
  getDefaultParametrization(projectToken) {
    let endpoint = `params/getDefaultParam/${projectToken}`;
    return API().get(endpoint);
  },

  /**
  * Setea el paramId escogido para ser el por defecto en la info de cada candidato en bbdd
  * en la tabla perf_Pers, columna parameters_id
  * @param {string} paramId 
  */
  async setCandidateParameterId(userProjectToken, paramId) {
    try {
      const response =
        await this.loadCandidatesGeneralView(
          userProjectToken,
          "es"
        );

        if (response.status !== 200 ) console.log('error en setCandidateParameterId fn')

        let candidatesProjectData = response.data;
        
        await this.postParamSelectedForCandidateBulk(paramId, candidatesProjectData.linkToken)

    } catch (error) {
      console.error("Error while posting data:", error);
    }
  },
  
  /**
   * Obtiene los datos de la encuesta de un candidato en particular, 
   * entre los datos estan los 108 datos, dividido en 3 bloques de a 36 elementos
   * @param {string} candidateToken  
   */
  getCandidateSurveyResult(candidateToken) {
    let endpoint = `candidate/${candidateToken}`;
    return API().get(endpoint);
  },
  
  /**
   * Obtiene el promedio de todas las respuestas de encuestas internas de un proyecto
   * @param {string} projectToken  
   */
  getAverageInnerSurvey(projectToken) {
    let endpoint = `params/getAverageInnerSurvey/${projectToken}`;
    return API().get(endpoint);
  },

  /**
   * Endpoint usado para los informes generados en la Lista de Proyectos,
   * en este proyecto se usa para actualizar la pestaña Team & Company Diagnose
   * @param {string} projectToken
   */
  getProjectListDataRaw(projectToken) {
    let endpoint = `dataRAW/${projectToken}`;
    return API().get(endpoint);
  },

  /**
   * Endpoint para excluir las parametrizaciones predefinidas (las que empiezan con *)
   * @param {string} projectToken 
   * @param {string} id id de la parametrización predefinida a ser excluida (eliminada)
   */
  excludePredefinedParametrization(projectToken, id) {
    let endpoint = `params/excludePredefinedParam/${projectToken}`;
    return API().post(endpoint, {
      paramId: id,
    });
  },

};
