<script setup>
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { computed, defineExpose, defineProps, reactive } from "vue";
import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import CandidateReportCover from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCover.vue";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";

import NoEnterpriseDataCandidateReportCover from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/NoEnterpriseDataCandidateReportCover.vue";

import CandidateReport6Cultures from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport6Cultures.vue";
import CandidateReportExecutiveSummary from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportExecutiveSummary.vue";

import NoEnterpriseDataCandidateReportExecutiveSummary from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/NoEnterpriseDataCandidateReportExecutiveSummary.vue";

import CandidateReportAppendix1 from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportAppendix1.vue";
import CandidateReportAppendix2 from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportAppendix2.vue";
import CandidateReportCurrentCulturalFit from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCurrentCulturalFit.vue";
import CandidateReportDesiredCulturalFit from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportDesiredCulturalFit.vue";
import CandidateReportMindsetOf from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportMindsetOf.vue";
import CandidateReportPredominantValues from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportPredominantValues.vue";

import CandidateReportIdealFit from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportIdealFit.vue";
import CandidateReport36Chart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport36Chart.vue";
import CandidateReportBack from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportBack.vue";
import Utils from "@/globals/Utils";

const props = defineProps({           
  candidateMindsetFit: Array,     
  userInfo: Object,     
});

const store = useStore();
//txt Dictionary
const PersonMet = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][97];
});
const ActualCulture = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][98];
});
const DesiredCulture = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][99];
});
const IdealValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][122];
});
const PersonValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][123];
});
const IdealMindset = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][124];
});
const PersonMindset = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][125];
});
const CultureValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][128];
});
const DesiredValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][129];
});

const $loading = useLoading({});
var loadingLayer = null;

const colorsForIdealChart = ['#02c071', '#34495e'];
const colorsForCultureChart = ["#00C3C0","#34495e"]; 
const colorsForDesireChart = ['#b39b3e', '#34495e'];
const culturalComparative = 'valores vividos'
const desireComparative = 'valores deseados'

const reportData = reactive({
  name: "-",
  token: "-",
  email: "-",
  project: "-",
  archInd: [],
  fitCandOrg: "-",
  fitCandDOrg: "-",
  fitCandOrgEval: "-",
  fitCandDOrgEval: "-",
  fitCandOrgEvalText: "-",
  fitCandDOrgEvalText: "-",
  pAOrg: [],
  pADes: [],
  archTitle: [],
  archMax1: "-",
  archMax2: "-",
  archMax1Text: "-",
  archMax2Text: "-",
  archMax1TextSummary: "-",
  archMax2TextSummary: "-",
  archMax1Area: "-",
  archMax1AreaProcessed: [],
  archMax2Area: "-",
  archMax2AreaProcessed: [],
  archMin1Area: "-",
  archMin1AreaProcessed: [],
  archMin2Area: "-",
  archMin2AreaProcessed: [],
  valuesTitle: [],
  valuesText: [],
  valuesCompetences: [],
  integrityScore16: [],
  integrityIndicator: "-",
  integrityRecommendation: "-",
  integrityRecommendationText: "-",
  integrityValues: [],
  integrityValBehaviour: [],
  integrityProb: [],
  mindsetFitIdealPercent: "-",
  paramName: "-",
  TxtCuadrante: "-",
  tokenText: "",
  predominance: [],
  predominantValues: [],
  mostLikelyBehaviours: [],

  archValuesChartSeries: [
    {
      name: "Predominance Values",
      data: [10, 20, 30, 40, 50, 60],
    },
  ],

  currentCandOrgFitSeries: [
    {
      name: PersonMet,
      data: [10, 20, 30, 40, 50, 60],
    },
    {
      name: ActualCulture,
      data: [10, 20, 30, 40, 50, 60],
    },
  ],

  desiredCandOrgFitSeries: [
    {
      name: PersonMet,
      data: [10, 20, 30, 40, 50, 60],
    },
    {
      name: DesiredCulture,
      data: [10, 20, 30, 40, 50, 60],
    },
  ],

  candidateIntegritySeries: [
    {
      name: "Cultura de Integridad en la Persona",
      data: [1, 2, 3, 4, 5, 6],
    },
  ],

  KPITTransformacional: "0",
  KPITTransaccional: "0",
  series_33: [
    {
      name: "Liderazgo transformacional",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
    },
  ],
  series_44: [
    {
      name: "Liderazgo transaccional",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
    },
  ],
  series_6_IdealVsPersona: [
    {
      name: IdealMindset,
      data: [10, 20, 30, 40, 50, 60],
    },
    {
      name: PersonMindset,
      data: [10, 20, 30, 40, 50, 60],
    },
  ],
  series_36_IdealVsPersona: [
    {
      name: IdealValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
    {
      name: PersonValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
  ],
  series_36_CulturaVsPersona: [
    {
      name: CultureValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
    {
      name: PersonValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
  ],
  series_36_DeseadaVsPersona: [
    {
      name: DesiredValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
    {
      name: PersonValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
  ],

  opcionesReport: [true, true, true, true],
  //LLAMAR AL DIÁLOGO ,PARAMETRIZAR EL REPORTE(TRES OPCIONES TRUE FALSE ) Y GENERAR EL REPORTE
  //opcinesReport[0] = Informe de Ajuste con cultura coorporativa
  //opcionesReport[1] = Informe de Comportamiento Ético (anexo1)
  //opcionesReport[2] = Informe de Liderazgo (anexo2)
  //opcionesReport[3] = Informe  de comparación con el MINDSETFIT
});

function updateReportData(newOptions) {
  reportData.opcionesReport = newOptions;
}

function generateReport(projectToken, candidateToken) {
  loadingLayer = $loading.show({
    color: "#34495e",
    opacity: "0.7",
  });

  cleanReportData();
  requestReportData(projectToken, candidateToken);
}

function cleanReportData() {
  reportData.name = "-";
  reportData.token = "-";
  reportData.email = "-";
  reportData.project = "-";
  reportData.archInd = [];
  reportData.fitCandOrg = "-";
  reportData.fitCandDOrg = "-";
  reportData.fitCandOrgEval = "-";
  reportData.fitCandDOrgEval = "-";
  reportData.fitCandOrgEvalText = "-";
  reportData.fitCandDOrgEvalText = "-";
  reportData.pAOrg = [];
  reportData.pADes = [];
  reportData.archTitle = [];
  reportData.archMax1 = "-";
  reportData.archMax2 = "-";
  reportData.archMax1Text = "-";
  reportData.archMax2Text = "-";
  reportData.archMax1TextSummary = "-";
  reportData.archMax2TextSummary = "-";
  reportData.archMax1Area = "-";
  reportData.archMax1AreaProcessed = [];
  reportData.archMax2Area = "-";
  reportData.archMax2AreaProcessed = [];
  reportData.archMin1Area = "-";
  reportData.archMin1AreaProcessed = [];
  reportData.archMin2Area = "-";
  reportData.archMin2AreaProcessed = [];
  reportData.valuesTitle = [];
  reportData.valuesText = [];
  reportData.valuesCompetences = [];
  reportData.integrityScore16 = [];
  reportData.integrityIndicator = "-";
  reportData.integrityRecommendation = "-";
  reportData.integrityRecommendationText = "-";
  reportData.integrityValues = [];
  reportData.integrityValBehaviour = [];
  reportData.integrityProb = [];

  reportData.tokenText = "";
  reportData.predominance = [];
  reportData.archValuesChartSeries[0].data = [10, 20, 30, 40, 50, 60];
  reportData.predominantValues = [];
  reportData.currentCandOrgFitSeries[0].data = [10, 20, 30, 40, 50, 60];
  reportData.currentCandOrgFitSeries[1].data = [10, 20, 30, 40, 50, 60];
  reportData.desiredCandOrgFitSeries[0].data = [10, 20, 30, 40, 50, 60];
  reportData.desiredCandOrgFitSeries[1].data = [10, 20, 30, 40, 50, 60];
  reportData.candidateIntegritySeries[0].data = [1, 2, 3, 4, 5, 6];
  reportData.mostLikelyBehaviours = [];
  reportData.KPITTransformacional = "0";
  reportData.KPITTransaccional = "0";
  reportData.series_33[0].data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  reportData.series_44[0].data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  reportData.series_6_IdealVsPersona[0].data = [10, 0, 0, 0, 0, 0];
  reportData.series_6_IdealVsPersona[1].data = [0, 0, 0, 10, 0, 0];
  reportData.series_36_IdealVsPersona[0].data = [
    20, 20, 40, 60, 50, 60, 10, 50, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 50,
    20, 40, 40, 5, 0, 0, 20, 40, 40, 50, 60, 10, 20, 30, 40, 50, 60,
  ];
  reportData.series_36_IdealVsPersona[1].data = [
    10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10,
    20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
  ];
  reportData.series_36_CulturaVsPersona[0].data = [
    20, 20, 40, 60, 50, 60, 10, 50, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 50,
    20, 40, 40, 5, 0, 0, 20, 40, 40, 50, 60, 10, 20, 30, 40, 50, 60,
  ];
  reportData.series_36_CulturaVsPersona[1].data = [
    10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10,
    20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
  ];
  reportData.series_36_DeseadaVsPersona[0].data = [
    20, 20, 40, 60, 50, 60, 10, 50, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 50,
    20, 40, 40, 5, 0, 0, 20, 40, 40, 50, 60, 10, 20, 30, 40, 50, 60,
  ];
  reportData.series_36_DeseadaVsPersona[1].data = [
    10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10,
    20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
  ];
  reportData.mindsetFitIdealPercent = "-";
  reportData.paramName = "-";
  reportData.TxtCuadrante = "-";
}

async function requestReportData(projectToken, candidateToken) {
  try {
    const [candDataRes, candSurveyRes, avProjectSurveyRes] = await Promise.all([
      ProfileEvaluationService.getCandidateReportData(
        projectToken,
        candidateToken,
        store.getters.currentLanguage
      ),
      ProfileEvaluationService.getCandidateSurveyResult(candidateToken),
      ProfileEvaluationService.getAverageInnerSurvey(props.userInfo.tokens.tokenDC),
    ]);

    let data = candDataRes.data;
    let candSurveyData = candSurveyRes.data;
    let avProjectSurveyData = avProjectSurveyRes.data;

    reportData.name = data.nombre;
    reportData.token = data.token;
    reportData.email = data.email || "";
    reportData.project = data.proyecto;
    reportData.archInd = data.cultura.ARQind;
    reportData.fitCandOrg = data.fitCandOrg;
    reportData.fitCandDOrg = data.fitCandDOrg;
    reportData.fitCandOrgEval = data.fitCandOrgEval ? data.fitCandOrgEval : 'BAJO';
    reportData.fitCandDOrgEval = data.fitCandDOrgEval ? data.fitCandDOrgEval : 'BAJO';
    reportData.fitCandOrgEvalText = data.fitCandOrgEvalTEXTO;
    reportData.fitCandDOrgEvalText = data.fitCandDOrgEvalTEXTO;
    reportData.pAOrg = data.cultura.pAorg;
    reportData.pADes = data.cultura.pAdes;
    reportData.archTitle = data.arqTITULO;
    reportData.archMax1 = data.ARQmax1;
    reportData.archMax2 = data.ARQmax2;
    reportData.archMax1Text = data.ARQmax1texto;
    reportData.archMax2Text = data.ARQmax2texto;
    reportData.archMax1TextSummary = data.ARQmax1textoResumen;
    reportData.archMax2TextSummary = data.ARQmax2textoResumen;
    reportData.archMax1Area = data.ARQmax1area;
    reportData.archMax1AreaProcessed = convertToList(reportData.archMax1Area);
    reportData.archMax2Area = data.ARQmax2area;
    reportData.archMax2AreaProcessed = convertToList(reportData.archMax2Area);
    reportData.archMin1Area = data.ARQmin1area;
    reportData.archMin1AreaProcessed = convertToList(reportData.archMin1Area);
    reportData.archMin2Area = data.ARQmin2area;
    reportData.archMin2AreaProcessed = convertToList(reportData.archMin2Area);
    reportData.valuesTitle = data.valoresTITULO;
    reportData.valuesText = data.valoresTEXTO;
    reportData.valuesCompetences = data.valoresCOMPETENCIAS;
    reportData.integrityScore16 = data.IntegridadPuntuacion16;
    reportData.integrityIndicator = data.IntegridadIndicador;
    reportData.integrityRecommendation = data.IntegridadRecomendacion;
    reportData.integrityRecommendationText = data.IntegridadRecomendTEXTO;
    reportData.integrityValues = data.IntegridadValores;
    reportData.integrityValBehaviour = data.IntegridadValCOMPORTAMIENTOS;
    reportData.integrityProb = data.IntegridadProb;

    reportData.tokenText = reportData.token.replace("CF-", "");
    reportData.predominance = processPredominance(data);
    reportData.archValuesChartSeries[0].data = data.cultura.ARQind;
    reportData.predominantValues = processPredominantValues(data);
    reportData.currentCandOrgFitSeries[0].data = data.cultura.ARQind;
    reportData.currentCandOrgFitSeries[1].data = data.cultura.pAorg;
    reportData.desiredCandOrgFitSeries[0].data = data.cultura.ARQind;
    reportData.desiredCandOrgFitSeries[1].data = data.cultura.pAdes;
    reportData.candidateIntegritySeries[0].data = data.IntegridadPuntuacion16;
    reportData.mostLikelyBehaviours = processMostLikelyBehaviours(data);
    reportData.KPITTransformacional = data.KPItransformacional;
    reportData.KPITTransaccional = data.KPItransaccional;
    reportData.series_33[0].data = data.transfo.LiderPuntuacion;
    reportData.series_44[0].data = data.transac.LiderPuntuacion;

    /*  data.cultura.VALind === undefined ? "N/D" : data.cultura.VALind; */

    reportData.series_6_IdealVsPersona[0].data =
      data.param === undefined ? [0, 0, 0, 0, 0, 0] : data.param.pApar;

    reportData.series_6_IdealVsPersona[1].data =
      data.param === undefined ? [0, 0, 0, 0, 0, 0] : data.cultura.ARQind;

    reportData.series_36_IdealVsPersona[0].data =
      data.param === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : data.param.mVpar;

    //VALind
    reportData.series_36_IdealVsPersona[1].data =
      data.cultura.VALind === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : data.cultura.VALind;
    reportData.series_36_CulturaVsPersona[0].data =
      avProjectSurveyData?.averageSurviesResults === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(avProjectSurveyData.averageSurviesResults.cultureOrganizationBlock);

    //VALind
    reportData.series_36_CulturaVsPersona[1].data =
      candSurveyData?.survey === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(candSurveyData.survey.personalBlock);
    reportData.series_36_DeseadaVsPersona[0].data =
      avProjectSurveyData?.averageSurviesResults === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(avProjectSurveyData.averageSurviesResults.desireOrganizationBlock);

    //VALind
    reportData.series_36_DeseadaVsPersona[1].data =
      candSurveyData?.survey === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(candSurveyData.survey.personalBlock);

    reportData.mindsetFitIdealPercent =
      data.param === undefined ? "N/D" : data.param.mindsetFit;

    reportData.paramName =
      data.proyectoParam === undefined ? "N/D" : data.proyectoParam;

    reportData.TxtCuadrante = data.textoCuadrante;
    generatePDF();
  } catch (error) {
    console.log(error);
    loadingLayer.hide();
  }
}

function generatePDF() {
  setTimeout(function () {
    html2canvas(
      document.querySelector("#candidate-report, #candidate-report2"),
      {
        width: 794,
        windowWidth: 794,
        scale: 3,
        useCORS: false,
        allowTaint: false,
      }
    ).then(function (canvas) {
      const imgData = canvas.toDataURL("image/jpeg", 0.5);
      const doc = new jsPDF({
        orientation: "p",
        compress: true,
        unit: "px",
        format: "a4",
        putOnlyUsedFonts: true,
        hotfixes: ["px_scaling"],
      });

      var pheight = doc.internal.pageSize.getHeight();
      var pixelRatioCorrector = 1;
      var cwidth = (canvas.width / 3) * pixelRatioCorrector;
      var cheight = (canvas.height / 3) * pixelRatioCorrector;

      var numberPages = Math.round(cheight / pheight);
      /* 
      // Calcular el DPI
      const widthInInches = 8.27; // Ancho de una página A4 en pulgadas
      const heightInInches = 11.69; // Alto de una página A4 en pulgadas
      const dpiWidth = canvas.width / widthInInches;
      const dpiHeight = canvas.height / heightInInches;
      const dpi = (dpiWidth + dpiHeight) / 2; // Promedio de ambas resoluciones

      console.log(`DPI: ${dpi}`); */

      for (let pageIndex = 0; pageIndex < numberPages; pageIndex++) {
        if (pageIndex > 0) doc.addPage();

        doc.addImage(
          imgData,
          "JPEG",
          0,
          pageIndex * -pheight,
          parseFloat(cwidth),
          parseFloat(cheight),
          "reportImage",
          "SLOW" // Calidad más alta
        );
      }

      doc.save(
        "Candidate_Report_" +
          reportData.name +
          "_" +
          new Date().toLocaleDateString() +
          ".pdf"
      );

      loadingLayer.hide();
    });
  }, 1200);
}

function processPredominance(data) {
  let predominance = [];

  for (var index in data.arqTITULO) {
    predominance.push({
      title: data.arqTITULO[index],
      value: data.cultura.ARQind[index],
    });
  }

  predominance.sort(function (a, b) {
    return b.value - a.value;
  });

  return predominance;
}

function processPredominantValues(data) {
  let predominantValues = [];

  for (var index in data.valoresTITULO) {
    predominantValues.push({
      title: data.valoresTITULO[index],
      text: data.valoresTEXTO[index],
      competences: data.valoresCOMPETENCIAS[index],
    });
  }

  return predominantValues;
}

function processMostLikelyBehaviours(data) {
  let mostLikelyBehaviours = [];

  for (var index in data.IntegridadValores) {
    mostLikelyBehaviours.push({
      value: data.IntegridadValores[index],
      behaviour: data.IntegridadValCOMPORTAMIENTOS[index],
      probability: data.IntegridadProb[index],
    });
  }

  return mostLikelyBehaviours;
}

function convertToList(text) {
  return text.split(",").map(function (item) {
    return item.trim();
  });
}

defineExpose({
  generateReport,
  updateReportData,
});
</script>
<template>
  <loading
    v-model:active="isLoading"
    color="#34495e"
    opacity="0.7"
    :is-full-page="true"
  />
  <div id="candidate-report" class="report-tpl-container">
    <CandidateReportCover
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[0]"
    />
    <NoEnterpriseDataCandidateReportCover
      v-bind:reportData="reportData"
      v-else
    />
    <CandidateReport6Cultures />
    <CandidateReportExecutiveSummary
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[0]"
    />
    <NoEnterpriseDataCandidateReportExecutiveSummary
      v-bind:reportData="reportData"
      v-else
    />
    <CandidateReportMindsetOf
      v-bind:reportData="reportData"
      v-bind:candidateMindsetFit="props.candidateMindsetFit"
    />
    <CandidateReportPredominantValues v-bind:reportData="reportData" />

    <CandidateReportIdealFit
      v-bind:reportData="reportData"
      v-bind:chartColors="colorsForIdealChart"
      v-if="
        reportData.opcionesReport[3] &&
        reportData.mindsetFitIdealPercent !== 'N/D'
      "
    />
    <CandidateReport36Chart
      v-bind:report36Data="reportData.series_36_IdealVsPersona"
      v-bind:name="reportData.name"
      v-bind:paramName="reportData.paramName"
      v-bind:chartColors="colorsForIdealChart"
      v-if="
        reportData.opcionesReport[3] &&
        reportData.mindsetFitIdealPercent !== 'N/D'
      "
    />

    <CandidateReportCurrentCulturalFit
      v-bind:reportData="reportData"
      v-bind:chartColors="colorsForCultureChart"
      v-if="reportData.opcionesReport[0]"
    />
    <CandidateReport36Chart
      v-bind:report36Data="reportData.series_36_CulturaVsPersona"
      v-bind:name="reportData.name"
      v-bind:comparative="culturalComparative"
      v-bind:enterprise="reportData.project"
      v-bind:chartColors="colorsForCultureChart"
      v-if="
        reportData.opcionesReport[3] &&
        reportData.mindsetFitIdealPercent !== 'N/D'
      "
    />
    
    <CandidateReportDesiredCulturalFit
      v-bind:reportData="reportData"
      v-bind:chartColors="colorsForDesireChart"
      v-if="reportData.opcionesReport[0]"
    />
    <CandidateReport36Chart
      v-bind:report36Data="reportData.series_36_DeseadaVsPersona"
      v-bind:name="reportData.name"
      v-bind:comparative="desireComparative"
      v-bind:enterprise="reportData.project"
      v-bind:chartColors="colorsForDesireChart"
      v-if="
        reportData.opcionesReport[3] &&
        reportData.mindsetFitIdealPercent !== 'N/D'
      "
    />

    <CandidateReportAppendix2
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[2]"
    />
    <CandidateReportAppendix1
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[1]"
    />

    <CandidateReportBack />
  </div>

  <!-- <div
    id="candidate-report2"
    class="report-tpl-container"
    v-if="reportData.pAOrg == null"
  >
    <NoEnterpriseDataCandidateReportCover v-bind:reportData="reportData" />
    <CandidateReport6Cultures />
    <NoEnterpriseDataCandidateReportExecutiveSummary
      v-bind:reportData="reportData"
    />
    <CandidateReportMindsetOf v-bind:reportData="reportData" />
    <CandidateReportPredominantValues v-bind:reportData="reportData" />
    <CandidateReportAppendix1 v-bind:reportData="reportData" />
    <CandidateReportBack />
  </div>

  <div id="candidate-report" class="report-tpl-container" v-else>
    <CandidateReportCover v-bind:reportData="reportData" />
    <CandidateReport6Cultures />
    <CandidateReportExecutiveSummary v-bind:reportData="reportData" />
    <CandidateReportMindsetOf v-bind:reportData="reportData" />
    <CandidateReportPredominantValues v-bind:reportData="reportData" />
    <CandidateReportCurrentCulturalFit v-bind:reportData="reportData" />
    <CandidateReportDesiredCulturalFit v-bind:reportData="reportData" />
    <CandidateReportAppendix1 v-bind:reportData="reportData" />
    <CandidateReportBack />
  </div> -->
</template>

<style lang="scss">
@import "@/assets/scss/candidate-report.scss";
@import "@/assets/scss/candidate-report2.scss";
</style>
