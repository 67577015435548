<template>
  <v-main>
    <v-card
      elevation="0"
      :height="main_sheet_height"
      :width="main_sheet_width"
      color="transparent"
      class="overflow-hidden"
    >
      <v-sheet
        :height="inner_sheets_height"
        width="100vw"
        color="transparent"
        class="text-center d-flex flex-column justify-space-around"
      >
        <!--Logo-->
        <v-row dense>
          <v-col cols="12" class="d-flex align-end justify-center">
            <v-avatar class="px-0" size="50%" rounded="0">
              <v-img src="@/assets/MinzerLogo.png" contain></v-img>
            </v-avatar>
          </v-col>
        </v-row>

        <!--To Do Test Button-->
        <v-row dense>
          <v-col cols="12">
            <p v-show="!show" class="text mt-5">{{ text1 }}</p>
            <p v-show="show" class="text mt-5">{{ text1_2 }}</p>
          </v-col>
        </v-row>

        <v-row dense class="d-flex justify-center">
          <v-col cols="2">
            <v-btn
              v-show="!show"
              block
              rounded="lg"
              density="comfortable"
              size="x-large"
              class="dotest_button mt-5 px-16"
              :href="user_info.urlTest"
              target="_blank"
              @click="change_button"
            >
              {{ dotest_button }}
            </v-btn>

            <v-btn
              v-show="show"
              block
              rounded="lg"
              density="comfortable"
              size="x-large"
              class="dotest_button mt-5 px-16"
              @click="this.$router.replace({ name: ROUTES.DASHBOARD })"
            >
              {{ goto_dashboard }}
            </v-btn>
          </v-col>
        </v-row>

        <!--Info Section-->
        <v-row dense class="d-flex align-center justify-center">
          <v-col cols="5" class="d-flex align-center justify-center">
            <div>
              <p class="text mt-8">
                {{ text2 }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
        :height="inner_sheets_height"
        width="100vw"
        color="transparent"
        class="text-center"
      >
        <v-row dense class="d-flex justify-center mt-10">
          <v-col cols="12" md="3" class="mt-10 d-flex justify-end">
            <v-img
              src="@/assets/BoxBg.png"
              max-height="100%"
              max-width="100%"
              contain
              class="d-flex align-center"
            >
              <v-row class="d-flex justify-center mt-3">
                <v-col cols="8">
                  <h3 class="text_box">{{ card1 }}</h3>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
          <v-col cols="12" md="3" class="mx-15 mt-10">
            <v-img
              src="../assets/BoxBg.png"
              max-height="100%"
              max-width="100%"
              contain
              class="d-flex align-center"
            >
              <v-row class="d-flex justify-center mt-3">
                <v-col cols="8">
                  <h3 class="text_box">{{ card2 }}</h3>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
          <v-col cols="12" md="3" class="mt-10">
            <v-img
              src="../assets/BoxBg.png"
              max-height="100%"
              max-width="100%"
              contain
              class="d-flex align-center"
            >
              <v-row class="d-flex justify-center mt-3">
                <v-col cols="9">
                  <h3 class="text_box">{{ card3 }}</h3>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>

        <!--To do another time button-->
        <v-row dense>
          <v-col cols="12" class="mt-16">
            <v-btn
              variant="text"
              class="text-decoration-underline x-small text-none"
              :ripple="false"
              style="color: #34495e"
              @click="another_time"
              >{{ another_time_button }}
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>
  </v-main>
</template>
 
   <script>
import { defineComponent } from "vue";
import { ROUTES } from "/src/router/names";
import { mapMutations, mapState } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";



export default defineComponent({
  name: "TestInfoView",

  components: {},

  data: () => ({
    //show
    show: false,
    //Components Labels
    text1: "",
    text1_2: "",
    dotest_button: "",
    goto_dashboard: "",
    text2: "",
    card1: "",
    card2: "",
    card3: "",
    another_time_button: "",

 

    ROUTES: ROUTES,
  }),

  computed: {
    ...mapState(["user_info"]),
  },

  methods: {
    ...mapMutations(["setTest_dialog_true"]),

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.text1 = texts[19];
      this.text1_2 = texts[25];
      this.dotest_button = texts[20];
      this.goto_dashboard = texts[26];
      this.text2 = texts[5];

      this.card1 = texts[21];
      this.card2 = texts[22];
      this.card3 = texts[23];
      this.another_time_button = texts[24];

    },

    another_time() {
      this.setTest_dialog_true();
      this.$router.push({ name: ROUTES.DASHBOARD });
    },

    change_button() {
      this.show = true;
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const main_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return window.innerHeight;
        case "lg":
          return window.innerHeight;
        case "xl":
          return window.innerHeight;
        case "xxl":
          return window.innerHeight;
      }

      return undefined;
    });

    const main_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return window.innerWidth;
        case "lg":
          return window.innerWidth;
        case "xl":
          return window.innerWidth;
        case "xxl":
          return window.innerWidth;
      }

      return undefined;
    });

    const inner_sheets_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (50 * window.innerHeight) / 100;
        case "lg":
          return (50 * window.innerHeight) / 100;
        case "xl":
          return (50 * window.innerHeight) / 100;
        case "xxl":
          return (50 * window.innerHeight) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      main_sheet_height,
      main_sheet_width,
      inner_sheets_height,
      mdAndUp,
      xsAndUp,
    };
  },
});
</script>

<style scoped>
.dotest_button {
  background-color: #34495e;
  color: white;
}

.text {
  color: #34495e;
  font-size: 20px;
  font-weight: 500;
}

.text_box {
  color: white;
  font-size: 100%;
}
</style>